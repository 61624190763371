@if (cs.comments$ | async; as items) {
  @if (items.length === 0) {
    <app-empty-space [message]="'activity.text.noCommentsYet' | translate">
      <app-new-item-button (click)="cs.openCreateCommentDialog()" />
    </app-empty-space>
  } @else {
    <p-timeline [value]="items" class="w-full">
      <ng-template pTemplate="opposite" let-event>
        <div class="text-sm text-700 font-semibold pt-1">
          {{ event.createdAt | date: "y-MM-dd" }}
        </div>
        <div class="text-xs text-600">
          {{ event.createdAt | date: "HH:mm" }}
        </div>
      </ng-template>
      <ng-template pTemplate="marker" let-event>
        <app-user-avatar [user]="event.actor"></app-user-avatar>
      </ng-template>
      <ng-template pTemplate="content" let-comment>
        <div
          class="border-2 border-dashed surface-border p-2 comment-wrapper"
          (mouseenter)="toggleCommentActions($event, options)"
          (mouseleave)="toggleCommentActions($event, options)"
        >
          <div [innerHTML]="comment.text"></div>
          <div class="gap-2 comment-actions" #options style="display: none">
            <span
              class="pi pi-pencil text-xs cursor-pointer"
              (click)="cs.openUpdateCommentDialog(comment)"
            ></span>
          </div>
        </div>
      </ng-template>
    </p-timeline>
    <hr />
    <div class="flex justify-content-end">
      <app-new-item-button
        (click)="cs.openCreateCommentDialog()"
      ></app-new-item-button>
    </div>
  }
}
