import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SlimContact } from '~/contact/models';
import { provideComponentStore } from '@ngrx/component-store';
import { ActivityDataDialogStore } from './activity-data-dialog.store';
import {
  Activity,
  ActivityPriorityEnum,
  ActivityStatus,
  ActivityType,
} from '~/activity/models';
import { User } from '~/user/models';
import { Subscription } from 'rxjs';
import { DateTimeService } from '~/@core/services/transformers/date-time.service';

@Component({
  selector: 'app-activity-data-dialog',
  templateUrl: './activity-data-dialog.component.html',
  providers: [provideComponentStore(ActivityDataDialogStore)],
})
export class ActivityDataDialogComponent implements OnDestroy, OnInit {
  private mainContactInAdditionalValidator: ValidatorFn = (control) => {
    const contact = control?.parent?.get('contact')?.value;
    const additionalContacts = control.value;
    console.log(contact, additionalContacts);
    if (
      contact &&
      additionalContacts?.length > 0 &&
      additionalContacts.some((c: SlimContact) => c.id === contact.id)
    ) {
      return { mainContactInAdditional: true };
    }

    return null;
  };

  public form = new FormGroup({
    title: new FormControl<string | null>(null, [Validators.required]),
    description: new FormControl<string | null>(null),
    status: new FormControl<ActivityStatus | null>(null, [Validators.required]),
    type: new FormControl<ActivityType | null>(null, [Validators.required]),
    contact: new FormControl<SlimContact | null>(null),
    additionalContacts: new FormControl<SlimContact[] | null>(null, [
      this.mainContactInAdditionalValidator,
    ]),
    actors: new FormControl<User[] | null>(null),
    supervisors: new FormControl<User[] | null>(null),
    priority: new FormControl<ActivityPriorityEnum | null>(
      ActivityPriorityEnum.MEDIUM,
      [Validators.required],
    ),
    startAt: new FormControl<Date | null>(null),
    endAt: new FormControl<Date | null>(null),
  });

  public contact?: SlimContact;

  public activity?: Activity;

  public parent?: Activity;

  private subs = new Subscription();

  constructor(
    protected readonly cs: ActivityDataDialogStore,
    private readonly config: DynamicDialogConfig,
    private readonly dateTimeService: DateTimeService,
  ) {
    this.activity = this.config.data?.activity;
    this.contact = this.config.data?.contact;
    this.parent = this.config.data?.parent;

    if (!this.contact) {
      this.form.get('additionalContacts')?.disable();
    }
  }

  public ngOnInit(): void {
    this.subscribeToStartTime();
    this.subscribeToFooterSave();
    this.subscribeForFormValid();
    this.subscribeForDisablingAdditionalContacts();

    this.setValuesFromActivity();
    this.alignWithPredefinedContact();
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public submit(): void {
    if (this.form.invalid) return;

    const actorsVal = this.form.get('actors')?.value;
    const supervisorsVal = this.form.get('supervisors')?.value;
    const contactVal = this.form.get('contact')?.value;
    const endAt = this.form.get('endAt')?.value;
    const startAt = this.form.get('startAt')?.value;
    const additionalContacts = this.form.get('additionalContacts')?.value;

    this.cs.submitTask({
      title: this.form.get('title')?.value as string,
      description: this.form.get('description')?.value as string,
      statusId: this.form.get('status')?.value?.id as string,
      typeId: this.form.get('type')?.value?.id as string,
      contactId: contactVal?.id,
      additionalContactIds: additionalContacts
        ? additionalContacts.map((contact: SlimContact) => contact.id)
        : [],
      actors: actorsVal ? actorsVal.map((actor) => actor.id) : [],
      parentId: this.parent?.id,
      supervisors: supervisorsVal
        ? supervisorsVal.map((supervisor) => supervisor.id)
        : [],
      priority: this.form.get('priority')?.value as ActivityPriorityEnum,
      estimatedEndAt: endAt
        ? this.dateTimeService.toApiDateTime(endAt)
        : undefined,
      estimatedStartAt: startAt
        ? this.dateTimeService.toApiDateTime(startAt)
        : undefined,
    });
  }

  private setValuesFromActivity() {
    if (!this.activity) return;

    const activity = this.activity;
    this.form.get('title')?.setValue(activity.title);
    this.form.get('description')?.setValue(activity.description || null);
    this.form.get('status')?.setValue(activity.status);
    this.form.get('type')?.setValue(activity.type);
    this.form.get('contact')?.setValue(activity.contact || null);
    this.form
      .get('additionalContacts')
      ?.setValue(activity.additionalContacts || null);
    this.form.get('actors')?.setValue(activity.actors);
    this.form.get('supervisors')?.setValue(activity.supervisors);
    this.form.get('priority')?.setValue(activity.priority);
    this.form
      .get('startAt')
      ?.setValue(
        activity.estimatedStartAt ? new Date(activity.estimatedStartAt) : null,
      );
    this.form
      .get('endAt')
      ?.setValue(
        activity.estimatedEndAt ? new Date(activity.estimatedEndAt) : null,
      );
  }

  private alignWithPredefinedContact() {
    if (!this.contact) return;

    this.form.get('contact')?.setValue(this.contact);
    this.form.get('contact')?.disable;
  }

  private subscribeToStartTime() {
    const startSub = this.form
      .get('startAt')
      ?.valueChanges.subscribe((value) => {
        if (!value) return;
        const endVal = this.form.get('endAt')?.value;
        if (endVal && endVal > value) {
          return;
        }
        const end = new Date(value);
        end.setMinutes(end.getMinutes() + 15);
        this.form.get('endAt')?.setValue(end);
      });
    this.subs.add(startSub);
  }

  private subscribeToFooterSave() {
    const sub = this.config.data.footerSubmit.subscribe(() => {
      this.submit();
    });
    this.subs.add(sub);
  }

  private subscribeForFormValid() {
    const sub = this.form.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.config.data.formBlocked.next(false);
      } else {
        this.config.data.formBlocked.next(true);
      }
    });
    this.subs.add(sub);
  }

  private subscribeForDisablingAdditionalContacts() {
    const sub = this.form.get('contact')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.form.get('additionalContacts')?.disable();
        this.form.get('additionalContacts')?.setValue(null);
        return;
      }

      this.form.get('additionalContacts')?.enable();
    });
    this.subs.add(sub);
  }
}
