<td style="width: 1rem">
  <button
    type="button"
    pButton
    pRipple
    [pRowToggler]="activity"
    (click)="toggleExpand.emit(activity)"
    class="p-button-text p-button-rounded p-button-plain"
    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
    [disabled]="disableExpand(activity)"
  ></button>
</td>

<td>
  <a
    [routerLink]="['/activities', activity.id]"
    class="block w-full cursor-pointer hover:text-primary mb-2 text-color"
    >{{ activity.title }}
  </a>
  <div class="flex align-items-center gap-2">
    <app-activity-type-badge [type]="activity.type"></app-activity-type-badge>
    <app-activity-status
      [activity]="activity"
      (activityChange)="activityUpdated.emit($event)"
    />
  </div>
</td>

@if (!small) {
  <td>
    <div class="flex gap-3 align-items-center justify-content-end">
      <app-users-group [users]="activity.actors" />
      @if (showContact && activity.contact) {
        <app-contact-link [contact]="activity.contact"></app-contact-link>
      }
    </div>
  </td>

  <td class="action-column">
    <div class="flex justify-content-end w-full px-0">
      <span #date class="white-space-nowrap">
        <app-activity-resolution-date [activity]="activity" />
      </span>
      <div style="display: none" #options>
        <div class="flex gap-2">
          <app-row-view-button [link]="['/activities', activity.id]" />
          <app-row-edit-button
            (edit)="$event.stopPropagation(); update.emit(activity)"
          />
          <app-row-delete-button
            (delete)="delete.emit(activity)"
            [disabled]="(canDelete$ | async) === false"
          />
        </div>
      </div>
    </div>
  </td>
} @else {
  <td>
    <div class="flex gap-3 align-items-center justify-content-end">
      <app-users-group [users]="activity.actors" />
      @if (showContact && activity.contact) {
        <app-contact-link [contact]="activity.contact"></app-contact-link>
      }
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-ellipsis-v"
        class="p-button-text h-2rem w-2rem"
        (click)="$event.stopPropagation(); menu.toggle($event)"
      ></button>
      <p-tieredMenu #menu [model]="menuItems" [popup]="true" appendTo="body">
        <ng-template pTemplate="item" let-item>
          <a pRipple class="flex align-items-center p-menuitem-link">
            <span [class]="item.icon" class="p-menuitem-icon"></span>
            <span class="ml-2">{{ item.label | translate }}</span>
          </a>
        </ng-template>
      </p-tieredMenu>
    </div>
  </td>
}
