import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Activity } from '../../models';
import {
  ActivityFilter,
  ActivityPatchStatusPayload,
  ActivityPayload,
} from './activity.payloads';
import { HttpClient } from '@angular/common/http';
import { ICollection, IPagination } from '~/@core/models';
import {
  filterUndefinedParams,
  getAllItemsWithFilterFactory,
} from '~/@core/utils';

@Injectable({
  providedIn: 'root',
})
export class ActivityHttpService {
  private readonly getAllItems = getAllItemsWithFilterFactory<
    ActivityFilter,
    Activity
  >(this.getList.bind(this));

  constructor(private readonly http: HttpClient) {}

  public getList(filter?: ActivityFilter, pagination?: IPagination) {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };
    return this.http.post<ICollection<Activity>>('/api/activity-lists', {
      ...filterUndefinedParams({
        ...filter,
        ...paginationQuery,
      }),
    });
  }

  public getAll(filter?: ActivityFilter) {
    return this.getAllItems(filter);
  }

  public getOne(id: string): Observable<Activity> {
    return this.http.get<Activity>(`/api/activities/${id}`);
  }

  public create(data: ActivityPayload): Observable<Activity> {
    return this.http.post<Activity>('/api/activities/', data);
  }

  public update(id: string, data: ActivityPayload): Observable<Activity> {
    return this.http.put<Activity>(`/api/activities/${id}`, data);
  }

  public patchStatus(
    id: string,
    data: ActivityPatchStatusPayload,
  ): Observable<Activity> {
    return this.http.patch<Activity>(`/api/activities/${id}/status`, data);
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/activities/${id}`);
  }
}
