import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ImportColumnConfiguration,
  ImportResult,
} from '~/@core/modules/importer/models';
import {
  catchError,
  exhaustMap,
  Observable,
  of,
  Subscription,
  switchMap,
  throwError,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  PeriodHttpService,
  SubscriptionHttpService,
} from '~/subscription/services/http';
import { isUUIDv4 } from '~/@core/utils';
import {
  SubscriptionDictionariesService,
  SubscriptionTypesService,
} from '~/subscription/services/data';
import {
  SubscriptionDictionary,
  SubscriptionType,
} from '~/subscription/models';
import { map } from 'rxjs/operators';
import { PickSubscriptionService } from '~/subscription/services/dialogs';
import { Router } from '@angular/router';
import { ContactGetterService } from '~/contact/@feature/services/contact-getter.service';
import { DictionaryItemHttpService } from '~/dictionary/http';
import { IDictionaryItem } from '~/dictionary/models';

@Component({
  selector: 'app-subscription-import',
  templateUrl: './subscription-import.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionImportComponent implements OnInit, OnDestroy {
  protected readonly columnConfigurations: ImportColumnConfiguration[] = [
    {
      key: 'contactIdentifier',
      name: 'subscription.label.contactIdentifier',
      required: true,
      type: 'string',
    },
    {
      key: 'contactName',
      name: 'subscription.label.contactName',
      required: false,
      type: 'string',
    },
    {
      key: 'subscriptionType',
      name: 'subscription.label.type',
      required: true,
      type: 'string',
    },
    {
      key: 'note',
      name: 'subscription.label.note',
      required: false,
      type: 'string',
    },
    {
      key: 'periodNote',
      name: 'subscription.label.periodNote',
      required: false,
      type: 'string',
    },
    {
      key: 'start',
      name: 'subscription.label.periodStart',
      required: true,
      type: 'string',
    },
    {
      key: 'end',
      name: 'subscription.label.periodEnd',
      required: true,
      type: 'string',
    },
  ];
  protected columnConfigurationsWithDictionaries?: ImportColumnConfiguration[];
  private types: SubscriptionType[] = [];
  private dictionaries: SubscriptionDictionary[] = [];
  private itemsMap: Record<string, IDictionaryItem[]> = {};
  private subs = new Subscription();

  constructor(
    private readonly contactGetterService: ContactGetterService,
    private readonly subscriptionHttp: SubscriptionHttpService,
    private readonly periodHttp: PeriodHttpService,
    private readonly typeService: SubscriptionTypesService,
    private readonly dictionariesService: SubscriptionDictionariesService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly subPickDialogService: PickSubscriptionService,
    private readonly router: Router,
    private readonly dictionaryItemHttpService: DictionaryItemHttpService,
  ) {}

  public ngOnInit() {
    this.getTypes();
    this.getDictionaries();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public importFunction = (data: any): Observable<ImportResult> => {
    const errors: string[] = [];

    return this.contactGetterService
      .getOrCreateContact$(data.contactIdentifier, {
        identifier: data.contactIdentifier,
        name: data.contactName,
      })
      .pipe(
        switchMap((contact) => {
          if (!contact) {
            return throwError(() => new Error('No contact found'));
          }

          const type = this.getType(data.subscriptionType);
          if (!type) {
            return throwError(() => new Error('No type found'));
          }

          return this.getDictionaryValues(data).pipe(
            switchMap((dictionaryValues) =>
              this.createSubscriptionGetter(contact.id, type.id).pipe(
                switchMap((sub) => {
                  if (!sub) {
                    return throwError(
                      () => new Error('No subscription found or created'),
                    );
                  }

                  return this.periodHttp
                    .create({
                      subscriptionId: sub.id,
                      start: new Date(data.start),
                      end: new Date(data.end),
                      note: data.periodNote,
                      dictionaryValues,
                    })
                    .pipe(
                      map(() => ({
                        resultId: sub.id,
                        resultLink: this.createLink(sub.id),
                        errors,
                      })),
                      catchError((err) => {
                        return of({
                          resultId: sub.id,
                          resultLink: this.createLink(sub.id),
                          errors: [err.message()],
                        });
                      }),
                    );
                }),
              ),
            ),
          );
        }),
        catchError((err: HttpErrorResponse) =>
          of({
            result: undefined,
            errors: [err.message],
          }),
        ),
      );
  };

  private getType(data: string) {
    if (isUUIDv4(data)) {
      return this.types.find((t) => t.id === data);
    }

    return this.types.find((t) => t.name.toLowerCase() === data.toLowerCase());
  }

  private getTypes() {
    const sub = this.typeService.getItems$(true).subscribe((types) => {
      this.types = types;
    });
    this.subs.add(sub);
  }

  private getDictionaries() {
    const sub = this.dictionariesService.getItems$(true).subscribe((items) => {
      this.dictionaries = items;
      this.columnConfigurationsWithDictionaries = [
        ...this.columnConfigurations,
        ...items.map(
          (d) =>
            ({
              key: `d[${d.id}]`,
              name: d.name,
              required: false,
              type: 'string',
            }) as ImportColumnConfiguration,
        ),
      ];
      this.cdRef.markForCheck();
    });
    this.subs.add(sub);
  }

  private createSubscriptionGetter(
    ownerId: string,
    typeId: string,
    note?: string,
  ) {
    return this.subscriptionHttp
      .getAll({
        ownerId,
        typeIds: [typeId],
      })
      .pipe(
        exhaustMap((list) => {
          if (list.total === 0)
            return this.subscriptionHttp.create({
              ownerId,
              typeId,
              note,
            });

          if (list.total === 1) {
            const sub = list.items[0];
            if (!note || sub.note !== note) {
              return of(sub);
            }
            return this.subscriptionHttp.update(sub.id, {
              ownerId: sub.owner.id,
              typeId: sub.type.id,
              note,
            });
          }

          return this.subPickDialogService.open(list.items);
        }),
      );
  }

  private createLink(id: string) {
    return (
      window.location.origin +
      this.router.serializeUrl(
        this.router.createUrlTree(['/subscriptions', id]),
      )
    );
  }

  private getDictionaryValues(data: any): Observable<
    Array<{
      dictionaryId: string;
      valueIds: string[];
    }>
  > {
    return new Observable((observer) => {
      const dictionaryValues: Array<{
        dictionaryId: string;
        valueIds: string[];
      }> = [];

      const dictionaryObservables = this.dictionaries.map((d) => {
        const value = data[`d[${d.id}]`];
        if (!value) return of(null);

        const values = value.split(',').map((v: string) => v.trim());
        const existingItems = this.itemsMap[d.id];

        if (existingItems) {
          return this.checkItemsInDictionary(
            d.id,
            d.name,
            values,
            dictionaryValues,
          );
        } else {
          return this.dictionaryItemHttpService
            .getAllForDictionary(d.dictionary.id)
            .pipe(
              map((c) => c.items),
              map((items: IDictionaryItem[]) => {
                this.itemsMap[d.id] = items;
                return this.checkItemsInDictionary(
                  d.id,
                  d.name,
                  values,
                  dictionaryValues,
                );
              }),
            );
        }
      });

      const sub = of(...dictionaryObservables)
        .pipe(exhaustMap((obs) => obs))
        .subscribe({
          next: () => {},
          error: (err) => observer.error(err),
          complete: () => {
            observer.next(dictionaryValues);
            observer.complete();
          },
        });

      this.subs.add(sub);
    });
  }

  private checkItemsInDictionary(
    dictionaryId: string,
    dictionaryName: string,
    values: string[],
    dictionaryValues: Array<{ dictionaryId: string; valueIds: string[] }>,
  ): Observable<null> {
    const valueIds = values.map((v: string) => {
      const item = this.itemsMap[dictionaryId].find((item) => {
        return item.value === v || item.id === v;
      });
      if (item) {
        return item.id;
      } else {
        throw new Error(`Item ${v} not found in dictionary ${dictionaryName}`);
      }
    });

    dictionaryValues.push({
      dictionaryId,
      valueIds,
    });

    return of(null);
  }
}
