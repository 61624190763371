<p-badge
  [ngClass]="{ 'cursor-pointer': !isResolved }"
  [value]="activity.status.name"
  [severity]="isResolved ? 'primary' : 'info'"
  [pTooltip]="
    isBusy || isResolved
      ? undefined
      : ('activity.label.changeStatus' | translate)
  "
  [tooltipDisabled]="isBusy || isResolved"
  (click)="!isResolved && statusOverlay.toggle($event)"
></p-badge>

<p-overlayPanel #statusOverlay>
  <ng-template pTemplate>
    @if (statuses$ | async; as statuses) {
      <p-listbox
        [options]="statuses"
        optionLabel="name"
        (onChange)="onStatusChange($event.value); statusOverlay.hide()"
      ></p-listbox>
    }
  </ng-template>
</p-overlayPanel>
