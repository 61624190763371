<div class="card">
  <div class="grid">
    <div class="col-12 xl:col-3">
      <h1 class="text-700 text-3xl mb-2 pb-2 border-bottom-1 border-gray-400">
        {{ "activity.header.activities" | translate }}
      </h1>

      @if (contactId$ | async; as contactId) {
        <h2 class="text-700 text-xl mb-2">
          {{ "activity.label.contact" | translate }}
        </h2>
        <div class="border-dashed border-1 border-gray-400 p-3">
          <app-contact-card [contactId]="contactId" [slim]="true" />
        </div>
      }

      @if (cs.parent$ | async; as parent) {
        <h2 class="text-700 text-xl mb-2">
          {{ "activity.header.parentActivity" | translate }}
        </h2>
        <div class="border-dashed border-1 border-gray-400 p-3">
          <div>
            <div class="flex justify-content-between align-items-center gap-3">
              <div class="card-title">
                <h4 class="text-xl mt-1 mb-2">{{ parent.title }}</h4>
              </div>
              <div class="flex align-items-end gap-2">
                <a
                  pButton
                  pRipple
                  icon="pi pi-link"
                  class="p-button-text p-button-rounded p-button-primary"
                  [routerLink]="['/activities', parent.id]"
                ></a>
              </div>
            </div>
            <div class="flex align-items-center gap-2">
              <app-activity-type-badge
                [type]="parent.type"
              ></app-activity-type-badge>
              <p-badge [value]="parent.status.name"></p-badge>
            </div>
          </div>
        </div>
      }

      <h2 class="text-700 text-xl mb-2">
        {{ "common.header.filter" | translate }}
      </h2>
      <div class="border-dashed border-1 border-gray-400 p-3">
        <app-activities-filter
          (filter)="updateFilter($event)"
        ></app-activities-filter>
      </div>
    </div>
    <div class="col-12 xl:col-9">
      <p-table
        #dt
        [value]="(cs.loadedList$ | async)?.items || []"
        [totalRecords]="(cs.loadedList$ | async)?.total || 0"
        [filters]="(commonTableFilter$ | async) || {}"
        [lazy]="true"
        [paginator]="(cs.showPaginator$ | async) || false"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        [currentPageReportTemplate]="'common.text.pagination' | translate"
        [rowsPerPageOptions]="[10, 25, 50]"
        [filterDelay]="500"
        dataKey="id"
        (onLazyLoad)="load($event)"
      >
        <ng-template pTemplate="caption">
          <app-table-header>
            <ng-container filter>
              <div class="flex flex-wrap align-items-center gap-2">
                <app-table-filter
                  (filter)="dt.filterGlobal($event, 'equals')"
                ></app-table-filter>
                @if (cs.isBusy$ | async) {
                  <p-progressSpinner
                    styleClass="w-2rem h-2rem"
                    strokeWidth="6"
                    animationDuration="1s"
                  />
                }
              </div>
            </ng-container>
            <ng-container actions>
              <div class="flex flex-wrap gap-2">
                <app-new-item-button (click)="cs.openCreateDialog()" />
                <app-import-button [routerLink]="'/activities/import'" />
                <app-export-button
                  (click)="cs.export()"
                  [disabled]="(cs.disabledExport$ | async) === true"
                />
              </div>
            </ng-container>
          </app-table-header>
        </ng-template>

        <ng-template pTemplate="body" let-activity let-expanded="expanded">
          <tr
            app-activity-row
            [activity]="activity"
            [expanded]="expanded"
            (toggleExpand)="dt.toggleRow($event.id)"
            (update)="cs.openUpdateDialog($event)"
            (delete)="cs.deleteItem($event.id)"
            (activityUpdated)="cs.updateItemAndRefresh($event)"
          ></tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-activity>
          <tr>
            <td colspan="5" class="border-left-1 border-solid pl-6">
              <app-activity-row-expand
                [activity]="activity"
              ></app-activity-row-expand>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <div class="pt-4">
            <app-empty-space
              [message]="'activity.text.emptyActivitiesList' | translate"
            >
              <app-new-item-button
                (click)="cs.openCreateDialog()"
              ></app-new-item-button>
            </app-empty-space>
          </div>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
