<div class="card">
  <div class="grid">
    <div class="col-12 xl:col-3">
      <h1 class="text-700 text-3xl mb-2 pb-2 border-bottom-1 border-gray-400">
        {{ "subscription.header.subscriptions" | translate }}
      </h1>
      <h3 class="text-700 text-xl mb-2">
        {{ "common.header.filter" | translate }}
      </h3>
      <div class="border-dashed border-1 border-gray-400 p-3">
        <div class="grid p-fluid formgrid" [formGroup]="filterForm">
          <div class="col-12 field">
            <label for="subscriptionType" class="text-900 font-semibold">{{
              "subscription.label.type" | translate
            }}</label>
            <app-subscription-type-multiselect
              id="subscriptionType"
              formControlName="type"
              [showClear]="true"
            ></app-subscription-type-multiselect>
          </div>
          <div class="col-6 field">
            <label for="endFrom" class="text-900 font-semibold">{{
              "subscription.label.endFrom" | translate
            }}</label>
            <p-calendar
              id="endFrom"
              formControlName="endFrom"
              [showClear]="true"
              dateFormat="yy-mm-dd"
            ></p-calendar>
          </div>
          <div class="col-6 field">
            <label for="endTo" class="text-900 font-semibold">{{
              "subscription.label.endTo" | translate
            }}</label>
            <p-calendar
              id="endTo"
              formControlName="endTo"
              [showClear]="true"
              dateFormat="yy-mm-dd"
            ></p-calendar>
          </div>
          <div class="col-6 field">
            <label for="startFrom" class="text-900 font-semibold">{{
              "subscription.label.startFrom" | translate
            }}</label>
            <p-calendar
              id="startFrom"
              formControlName="startFrom"
              dateFormat="yy-mm-dd"
              [showClear]="true"
            ></p-calendar>
          </div>
          <div class="col-6 field">
            <label for="startTo" class="text-900 font-semibold">{{
              "subscription.label.startTo" | translate
            }}</label>
            <p-calendar
              id="startTo"
              formControlName="startTo"
              dateFormat="yy-mm-dd"
              [showClear]="true"
            ></p-calendar>
          </div>
          @for (dictionary of dictionaries; track dictionary.id) {
            <div class="col-12 field">
              <label class="text-900 font-semibold">{{
                dictionary.name
              }}</label>
              <app-dictionary-item-select
                [dictionary]="dictionary.dictionary"
                [formControlName]="dictionaryFormKey(dictionary.id)"
              ></app-dictionary-item-select>
            </div>
          }
        </div>
      </div>
    </div>

    <div class="col">
      <p-table
        #dt
        *ngIf="cs.loadedList$ | async as list"
        [value]="list.items"
        [totalRecords]="list.total"
        [lazy]="true"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        [currentPageReportTemplate]="'common.text.pagination' | translate"
        [rowsPerPageOptions]="[10, 25, 50]"
        [filterDelay]="500"
        dataKey="id"
        (onLazyLoad)="load($event)"
      >
        <ng-template pTemplate="caption">
          <app-table-header>
            <ng-container filter>
              <app-table-filter
                (filter)="dt.filterGlobal($event, 'equals')"
              ></app-table-filter>
            </ng-container>
            <ng-container actions>
              <div class="flex flex-wrap gap-2">
                <app-new-item-button (click)="cs.showCreateDialog()" />
                <app-import-button [routerLink]="'/subscriptions/import'" />
                <app-export-button
                  (click)="cs.export()"
                  [disabled]="(cs.disabledExport$ | async) === true"
                />
              </div>
            </ng-container>
          </app-table-header>
        </ng-template>

        <ng-template pTemplate="body" let-subscription let-expanded="expanded">
          <tr
            (mouseenter)="toggleOptions($event, options, date)"
            (mouseleave)="toggleOptions($event, options, date)"
          >
            <td style="width: 1rem">
              <button
                type="button"
                pButton
                pRipple
                [pRowToggler]="subscription"
                (click)="dt.toggleRow(subscription.id)"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                [disabled]="noExpandData(subscription)"
              ></button>
            </td>
            <td>
              <a
                class="text-700 font-semibold cursor-pointer hover:text-primary mb-2 text-color"
                [routerLink]="['/subscriptions', subscription.id]"
                >{{ subscription?.type.name }}</a
              >
            </td>
            <td>
              <div class="flex justify-content-end w-full">
                <app-contact-link
                  [contact]="subscription.owner"
                ></app-contact-link>
              </div>
            </td>
            <td class="text-right button-column">
              <div class="flex justify-content-end w-full px-0">
                <div #date>
                  <div class="text-xs white-space-nowrap">
                    {{ subscription?.start | date: "y-MM-dd" }}
                  </div>
                  <div
                    class="white-space-nowrap font-bold"
                    [ngClass]="endDateClass(subscription)"
                  >
                    {{ subscription?.end | date: "y-MM-dd" }}
                  </div>
                </div>
                <div style="display: none" #options>
                  <app-actions-button
                    [subscription]="subscription"
                  ></app-actions-button>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-subscription>
          <tr>
            <td colspan="4" class="border-left-1 border-solid pl-6">
              <app-subscription-expand
                [subscription]="subscription"
              ></app-subscription-expand>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
