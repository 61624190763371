<form class="flex flex-column gap-3" [formGroup]="form" (ngSubmit)="submit()">
  <div class="grid formgrid p-fluid mt-4">
    <div class="field col-12 sm:col-6">
      <label for="title" class="text-900 font-semibold required">{{
        "activity.label.title" | translate
      }}</label>
      <input pInputText formControlName="title" id="title" />
      <app-validation-errors formControlName="title">
        <app-validation-error formControlName="title" error="required" />
      </app-validation-errors>
    </div>

    <div class="field col-12 sm:col-2">
      <label for="type" class="text-900 font-semibold required">{{
        "activity.label.type" | translate
      }}</label>
      <app-activity-type-dropdown formControlName="type" id="type" />
      <app-validation-errors formControlName="type">
        <app-validation-error formControlName="type" error="required" />
      </app-validation-errors>
    </div>

    <div class="field col-12 sm:col-2">
      <label for="status" class="text-900 font-semibold required">{{
        "activity.label.status" | translate
      }}</label>
      <app-activity-status-dropdown formControlName="status" id="status" />
      <app-validation-errors formControlName="status">
        <app-validation-error formControlName="status" error="required" />
      </app-validation-errors>
    </div>

    <div class="field col-12 sm:col-2">
      <label for="priority" class="text-900 font-semibold">{{
        "activity.label.priority" | translate
      }}</label>
      <app-activity-priority-dropdown
        formControlName="priority"
        id="priority"
      />
    </div>

    <div class="field col-12 sm:col-6">
      <label for="contact" class="text-900 font-semibold">{{
        "activity.label.contact" | translate
      }}</label>
      @if (contact) {
        <div class="py-1">
          <p-chip label="{{ contact.name }}"></p-chip>
        </div>
      } @else {
        <app-contact-search
          id="contact"
          formControlName="contact"
          appendTo="body"
        ></app-contact-search>
      }
    </div>

    <div class="field col-3">
      <label for="startAt" class="text-900 font-semibold">{{
        "activity.label.startAt" | translate
      }}</label>
      <p-calendar
        [showTime]="true"
        [showClear]="true"
        [stepMinute]="15"
        id="startAt"
        appendTo="body"
        dateFormat="yy-mm-dd"
        formControlName="startAt"
      ></p-calendar>
    </div>

    <div class="field col-3">
      <label for="endAt" class="text-900 font-semibold">{{
        "activity.label.endAt" | translate
      }}</label>
      <p-calendar
        [showTime]="true"
        [showClear]="true"
        [stepMinute]="15"
        id="endAt"
        appendTo="body"
        dateFormat="yy-mm-dd"
        formControlName="endAt"
      ></p-calendar>
    </div>

    <div class="field col-12">
      <label for="contact" class="text-900 font-semibold">{{
        "activity.label.additionalContacts" | translate
      }}</label>
      <app-contact-search
        class="w-full"
        id="additionalContacts"
        formControlName="additionalContacts"
        [multiple]="true"
        appendTo="body"
      ></app-contact-search>
      <app-validation-errors formControlName="additionalContacts">
        <app-validation-error
          formControlName="additionalContacts"
          error="mainContactInAdditional"
          [message]="'contact.validation.mainContactInAdditional' | translate"
        />
      </app-validation-errors>
    </div>

    <div class="field col-6">
      <label for="actors" class="text-900 font-semibold">{{
        "activity.label.actors" | translate
      }}</label>
      <app-multiple-user-dropdown
        id="actors"
        formControlName="actors"
      ></app-multiple-user-dropdown>
    </div>

    <div class="field col-6">
      <label for="supervisors" class="text-900 font-semibold">{{
        "activity.label.supervisors" | translate
      }}</label>
      <app-multiple-user-dropdown
        id="supervisors"
        formControlName="supervisors"
      ></app-multiple-user-dropdown>
    </div>

    <div class="field col-12">
      <label for="description" class="text-900 font-semibold">{{
        "activity.label.description" | translate
      }}</label>
      <app-editor id="description" formControlName="description"></app-editor>
    </div>
  </div>
</form>
