@if (cs.activity$ | async; as activity) {
  <div class="card">
    <div class="card-body py-2">
      <div class="grid">
        <div class="col-6">
          <div class="border-bottom-1 border-gray-300 pb-1 text-sm text-600">
            {{ "activity.label.actors" | translate }}
          </div>
          <div class="pt-2">
            <app-users-group [users]="activity.actors"></app-users-group>
          </div>
        </div>
        <div class="col-4">
          <div class="border-bottom-1 border-gray-300 pb-1 text-sm text-600">
            {{ "activity.label.supervisors" | translate }}
          </div>
          <div class="pt-2">
            <app-users-group [users]="activity.supervisors"></app-users-group>
          </div>
        </div>
        <div class="col-2">
          <div
            class="border-bottom-1 border-gray-300 pb-1 text-center text-sm text-600"
          >
            {{ "activity.label.creator" | translate }}
          </div>
          <div class="pt-2 flex justify-content-center">
            <app-user-avatar
              size="normal"
              [user]="activity.creator"
            ></app-user-avatar>
          </div>
        </div>
      </div>

      @if (activity.estimatedStartAt) {
        <div class="flex justify-content-between align-items-center py-1">
          <span>{{ "activity.label.startAt" | translate }}</span>
          <div>{{ activity.estimatedStartAt | date: "y-MM-dd HH:mm" }}</div>
        </div>
      }

      @if (activity.estimatedEndAt) {
        <div class="flex justify-content-between align-items-center py-1">
          <span>{{ "activity.label.endAt" | translate }}</span>
          <div>{{ activity.estimatedEndAt | date: "y-MM-dd HH:mm" }}</div>
        </div>
      }

      @if (activity.resolvedAt) {
        <div class="flex justify-content-between align-items-center py-1">
          <span>{{ "activity.label.finishedAt" | translate }}</span>
          <div>{{ activity.resolvedAt | date: "y-MM-dd HH:mm" }}</div>
        </div>
      }

      @if (activity.description) {
        <div
          class="border-1 border-dashed surface-border p-2 mt-2"
          [innerHTML]="activity.description"
        ></div>
      }
    </div>
  </div>
}
