import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  Activity,
  ActivityResolutionEnum,
  ActivityStatus,
} from '~/activity/models';
import { ActivityHttpService } from '~/activity/services/http/activity.http-service';
import { Observable } from 'rxjs';
import { selectActivityStatuses } from '~/activity/@root/store/activity-status/activity-status.selectors';
import { Store } from '@ngrx/store';
import { checkActivityStatuses } from '~/activity/@root/store/activity-status/activity-status.actions';

@Component({
  selector: 'app-activity-status',
  templateUrl: './activity-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityStatusComponent {
  @Input() activity!: Activity;
  @Output() activityChange = new EventEmitter<Activity>();
  @Output() isUpdating = new EventEmitter<boolean>();

  protected statuses$: Observable<ActivityStatus[]> = this.store.select(
    selectActivityStatuses,
  );

  protected isBusy = false;

  constructor(
    private readonly store: Store,
    private readonly activityService: ActivityHttpService,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    this.store.dispatch(checkActivityStatuses());
  }

  get isResolved(): boolean {
    return this.activity.status.resolution === ActivityResolutionEnum.Finished;
  }

  onStatusChange(newStatus: ActivityStatus): void {
    this.isBusy = true;
    this.isUpdating.emit(true);
    this.cdRef.markForCheck();
    this.activityService
      .patchStatus(this.activity.id, {
        statusId: newStatus.id,
      })
      .subscribe((updatedActivity: Activity) => {
        this.activityChange.emit(updatedActivity);
        this.isUpdating.emit(false);
        this.isBusy = false;
        this.cdRef.markForCheck();
      });
  }
}
