import { ChangeDetectionStrategy, Component } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { SubscriptionViewStore } from './subscription-view.store';
import { selectUserHasRole } from '~/auth/store/auth.selectors';
import { Store } from '@ngrx/store';
import { ISubscription } from '~/subscription/models';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-subscription-view',
  templateUrl: './subscription-view.component.html',
  providers: [provideComponentStore(SubscriptionViewStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionViewComponent {
  protected canDelete$ = this.store.select(
    selectUserHasRole(['admin', 'manager']),
  );

  constructor(
    public readonly cs: SubscriptionViewStore,
    private readonly store: Store,
  ) {}

  protected endDateClass(row: ISubscription) {
    const date = DateTime.fromISO(row.end);
    return {
      'text-red-600': date < DateTime.now(),
      'text-orange-700': date < DateTime.now().plus({ days: 15 }),
    };
  }
}
