import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ChipModule } from 'primeng/chip';
import { DialogService } from 'primeng/dynamicdialog';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { BadgeModule } from 'primeng/badge';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { TooltipModule } from 'primeng/tooltip';

import { SharedModule } from '~/shared.module';
import { ContactModule } from '~/contact/contact.module';
import { UserModule } from '~/user';
import { SharedExtModule } from '~/shared-ext.module';

import {
  ActivityCommentDataDialogService,
  ActivityDataDialogService,
} from './services/dialogs';
import { ActivityStatusDropdownComponent } from './components/activity-status-dropdown';
import {
  ActivityDataDialogComponent,
  ActivityDataDialogFooterComponent,
  ActivityDataDialogHeaderComponent,
} from './components/activity-data-dialog';
import { ActivityTypeDropdownComponent } from './components/activity-type-dropdown';
import { ActivityPriorityDropdownComponent } from './components/activity-priority-dropdown';
import { ActivityRowExpandComponent } from './components/activity-row-expand';
import {
  ActivityCommentEditDialogComponent,
  ActivityCommentEditDialogFooterComponent,
} from './components/activity-comment-edit-dialog';

import { ActivityTypeBadgeComponent } from './components/activity-type-badge';
import { ActivityRowComponent } from './components/activity-row';
import { ActivityResolutionDateComponent } from './components/activity-resolution-date';
import { ActivityResolutionDropdownComponent } from './components/activity-resolution-dropdown';
import { ActivityMultipleTypeDropdownComponent } from './components/activity-multiple-type-dropdown';
import { ActivityStatusComponent } from './components/activity-status/activity-status.component';

const componentsToExport = [
  ActivityStatusDropdownComponent,
  ActivityTypeDropdownComponent,
  ActivityPriorityDropdownComponent,
  ActivityRowExpandComponent,
  ActivityRowComponent,
  ActivityTypeBadgeComponent,
  ActivityResolutionDropdownComponent,
  ActivityMultipleTypeDropdownComponent,
  ActivityStatusComponent,
];

@NgModule({
  imports: [
    SharedModule,
    SharedExtModule,
    ContactModule.forFeature(),
    UserModule.forFeature(),
    RouterModule,
    InputTextareaModule,
    ChipModule,
    TieredMenuModule,
    MultiSelectModule,
    BadgeModule,
    OverlayPanelModule,
    ListboxModule,
    TooltipModule,
  ],
  providers: [
    DialogService,
    ActivityDataDialogService,
    ActivityCommentDataDialogService,
  ],
  declarations: [
    ActivityDataDialogComponent,
    ActivityDataDialogFooterComponent,
    ActivityDataDialogHeaderComponent,
    ActivityCommentEditDialogComponent,
    ActivityCommentEditDialogFooterComponent,
    ActivityResolutionDateComponent,
    ActivityStatusComponent,

    ...componentsToExport,
  ],
  exports: [...componentsToExport, ActivityResolutionDateComponent],
})
export class ActivityCommonModule {}
